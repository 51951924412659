import { SelectOption } from 'src'
import { Contributor } from '../../interfaces/contributors/ContributorsInterfaces'
import Message from '../Message'
import { PERSONNE_MORALE, PERSONNE_PHYSIQUE } from './PersonConstants'

// TYPE D'INTERVENANT

export const CONTRIBUTOR_DEPOSITOR = {
  value: 'depositor'
}

export const CONTRIBUTOR_DEPOSITORS = {
  value: 'depositors'
}

export const CONTRIBUTOR_OPPONENTS = {
  value: 'opponents'
}

export const CONTRIBUTOR_OPPONENT = {
  value: 'opponent'
}

export const CONTRIBUTOR_AGENT = {
  value: 'agent'
}

export const CONTRIBUTOR_OLD_AGENTS = {
  value: 'old_agents'
}

export const CONTRIBUTOR_AGENT_DEPOSITOR = {
  value: 'agentDepositor'
}

export const CONTRIBUTOR_RECIPIENT = {
  value: 'recipient'
}

export const CONTRIBUTOR_SIGNATORY = {
  value: 'signatory'
}

export const CONTRIBUTOR_ADDRESS_BOOK = {
  value: 'addressBook'
}

export const CONTRIBUTOR_PAYMENT = {
  value: 'paymentContributor'
}

export const CONTRIBUTOR_HOLDERS = {
  value: 'holders'
}

export const CONTRIBUTOR_OLD_HOLDERS = {
  value: 'old_holders'
}

export const CONTRIBUTOR_HOLDERS_FRMI = {
  value: 'holders_frmi'
}

export const CONTRIBUTOR_HOLDERS_CONTESTED_MARK = {
  value: 'holdersContestedMark'
}

export const CONTRIBUTOR_OTHER_APPLICANTS = {
  value: 'otherApplicants'
}

export const CONTRIBUTOR_BENEFICIARIES = {
  value: 'beneficiaries'
}

export const CONTRIBUTOR_OMPI = {
  value: 'OMPI'
}

export const CONTRIBUTOR_DEPOSITOR_ND = {
  value: 'depositorsNd'
}

export const CONTRIBUTOR_AGENTS_TMC = {
  value: 'agentsTMC'
}

export const CONTRIBUTOR_RECIPIENTS_TMC = {
  value: 'recipientsTMC'
}
export const CONTRIBUTOR_ORGANISERS = {
  value: 'organisers'
}

export const CONTRIBUTOR_LICENSEE = {
  value: 'licensee'
}

export const CONTRIBUTOR_OLD_LICENSEES = {
  value: 'old_licensees'
}

export const CONTRIBUTOR_PAYER = {
  value: 'payer'
}

export const DEPOSIT_CONTRIBUTORS_TYPES = {
  DEPOSITORS: CONTRIBUTOR_DEPOSITORS,
  AGENT: CONTRIBUTOR_AGENT,
  RECIPIENT: CONTRIBUTOR_RECIPIENT,
  SIGNATORY: CONTRIBUTOR_SIGNATORY,
  ADDRESS_BOOK: CONTRIBUTOR_ADDRESS_BOOK
}

export const NULLITY_CONTRIBUTORS_TYPES = {
  DEPOSITORS: CONTRIBUTOR_DEPOSITORS,
  AGENT: CONTRIBUTOR_AGENT,
  RECIPIENT: CONTRIBUTOR_RECIPIENT,
  SIGNATORY: CONTRIBUTOR_SIGNATORY,
  ADDRESS_BOOK: CONTRIBUTOR_ADDRESS_BOOK,
  HOLDERS_CONTESTED_MARK: CONTRIBUTOR_HOLDERS_CONTESTED_MARK
}

export const REVOCATION_CONTRIBUTORS_TYPES = {
  DEPOSITORS: CONTRIBUTOR_DEPOSITORS,
  AGENT: CONTRIBUTOR_AGENT,
  RECIPIENT: CONTRIBUTOR_RECIPIENT,
  SIGNATORY: CONTRIBUTOR_SIGNATORY,
  ADDRESS_BOOK: CONTRIBUTOR_ADDRESS_BOOK,
  HOLDERS_CONTESTED_MARK: CONTRIBUTOR_HOLDERS_CONTESTED_MARK
}

export const OPPOSITION_CONTRIBUTORS_TYPES = {
  OPPONENTS: CONTRIBUTOR_OPPONENTS,
  AGENT: CONTRIBUTOR_AGENT,
  RECIPIENT: CONTRIBUTOR_RECIPIENT,
  SIGNATORY: CONTRIBUTOR_SIGNATORY
}

export const DIVISION_CONTRIBUTORS_TYPES = {
  DEPOSITORS: CONTRIBUTOR_DEPOSITORS,
  AGENT: CONTRIBUTOR_AGENT,
  RECIPIENT: CONTRIBUTOR_RECIPIENT,
  SIGNATORY: CONTRIBUTOR_SIGNATORY
}

export const INSCRIPTION_CONTRIBUTORS_TYPES = {
  DEPOSITORS: CONTRIBUTOR_DEPOSITORS,
  OTHER_APPLICANTS: CONTRIBUTOR_OTHER_APPLICANTS,
  AGENT: CONTRIBUTOR_AGENT,
  RECIPIENT: CONTRIBUTOR_RECIPIENT,
  SIGNATORY: CONTRIBUTOR_SIGNATORY,
  ORGANISERS: CONTRIBUTOR_ORGANISERS,
  BENEFICIARIES: CONTRIBUTOR_BENEFICIARIES
}

export const FRMI_CONTRIBUTORS_TYPES = {
  DEPOSITORS: CONTRIBUTOR_DEPOSITORS,
  HOLDERS: CONTRIBUTOR_HOLDERS_FRMI,
  OLD_HOLDERS: CONTRIBUTOR_OLD_HOLDERS,
  LICENCEE: CONTRIBUTOR_LICENSEE,
  OLD_LICENCEES: CONTRIBUTOR_OLD_LICENSEES,
  AGENT: CONTRIBUTOR_AGENT,
  RECIPIENT: CONTRIBUTOR_RECIPIENT,
  SIGNATORY: CONTRIBUTOR_SIGNATORY,
  ADDRESS_BOOK: CONTRIBUTOR_ADDRESS_BOOK,
  HOLDERS_CONTESTED_MARK: CONTRIBUTOR_HOLDERS_CONTESTED_MARK
}

export const RENEWAL_CONTRIBUTORS_TYPES = {
  DEPOSITORS: CONTRIBUTOR_DEPOSITORS,
  AGENT: CONTRIBUTOR_AGENT,
  RECIPIENT: CONTRIBUTOR_RECIPIENT,
  SIGNATORY: CONTRIBUTOR_SIGNATORY
}

export const REVOCATION_STATEMENT_CONTRIBUTORS_TYPES = {
  DEPOSITORS: CONTRIBUTOR_DEPOSITORS,
  RECIPIENT: CONTRIBUTOR_RECIPIENT,
  SIGNATORY: CONTRIBUTOR_SIGNATORY
}

export const OFFICIAL_DOCUMENTS_CONTRIBUTORS_TYPES = {
  DEPOSITORS: CONTRIBUTOR_DEPOSITORS,
  RECIPIENT: CONTRIBUTOR_RECIPIENT
}

export const DEFAULT_CONTRIBUTOR: Contributor = {
  type: PERSONNE_PHYSIQUE.value,
  address: { country: 'FR' },
  hasBeenModifiedByInscription: false
}

export const DEFAULT_CONTRIBUTOR_OP: Contributor = {
  type: PERSONNE_PHYSIQUE.value,
  hasBeenModifiedByInscription: false
}

/**
 * Pour afficher le ou les boutons de réinitialisation, on doit vérfier que certains champs sont remplis
 * Hors, certains sont remplis par défaut (type,civilité), selon le type d'intervenant.
 * Donc on exclut ces champs pour la condition d'affichage du bouton de réinitialisation
 */
export const CONTRIBUTOR_RESET_CHECK_EXCLUDED_FIELDS = {
  depositors: ['type', 'address'],
  opponents: ['type', 'address'],
  agent: ['type', 'address'],
  recipient: ['type', 'consentNotificationByEmail', 'email', 'address'],
  signatory: ['email', 'type', 'address']
}

export const DEFAULT_CONTRIBUTOR_COLLECTIVITY: Contributor = {
  type: PERSONNE_MORALE.value
}

export const CONTRIBUTORS_TABLE_TITLE_MODAL_ADD_RECORD = [
  {
    label: Message.contributor_type
  },
  {
    label: Message.contributor_name
  },
  {
    label: Message.contributor_address
  }
]

/**
 * Code de contributeur
 */

/** Opposants */
export const OPPONENT = {
  value: 'AGENT',
  label: Message.etsaccount_code_opponent_label
}

/** Déposant (Opposition) */
export const OPPOSITION_DEPOSITOR = {
  value: 'DEPOSITOR',
  label: Message.opposition_etsaccount_code_depositor_label
}

/** Demandeur (Nullité/déchéance) */
export const ND_DEPOSITOR = {
  value: 'AGENT',
  label: Message.nullity_etsaccount_code_depositor_label
}

/** Titulaires de la marque contestée */
export const HOLDER_CONTESTED_MARK = {
  value: 'AGENT_TMC',
  label: Message.etsaccount_code_holder_contested_mark_label
}

export const OPPOSITION_ETS_ACCOUNT_CONTRIBUTORS_CODES: SelectOption[] = [
  OPPONENT,
  OPPOSITION_DEPOSITOR
]

export const ND_ETS_ACCOUNT_CONTRIBUTORS_CODES: SelectOption[] = [
  ND_DEPOSITOR,
  HOLDER_CONTESTED_MARK
]

export const CONTRIBUTOR_SHOW_BY_TARGET = {
  [CONTRIBUTOR_OPPONENT.value]: [CONTRIBUTOR_OPPONENT.value, CONTRIBUTOR_OPPONENTS.value, CONTRIBUTOR_RECIPIENT.value, CONTRIBUTOR_AGENT.value],
  [CONTRIBUTOR_DEPOSITOR.value]: [CONTRIBUTOR_DEPOSITOR.value, CONTRIBUTOR_AGENT_DEPOSITOR.value],
  [CONTRIBUTOR_DEPOSITORS.value]: [CONTRIBUTOR_DEPOSITOR_ND.value, CONTRIBUTOR_DEPOSITORS.value, CONTRIBUTOR_AGENT.value, CONTRIBUTOR_DEPOSITOR_ND.value, CONTRIBUTOR_RECIPIENT.value],
  [CONTRIBUTOR_HOLDERS_CONTESTED_MARK.value]: [CONTRIBUTOR_AGENTS_TMC.value, CONTRIBUTOR_RECIPIENTS_TMC.value, CONTRIBUTOR_HOLDERS_CONTESTED_MARK.value],
  [CONTRIBUTOR_OMPI.value]: [CONTRIBUTOR_OMPI.value]
}
