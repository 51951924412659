import {
  Address,
  CIVILITE_MADAME,
  CIVILITE_MONSIEUR,
  Contributor,
  CONTRIBUTOR_DEPOSITORS,
  CONTRIBUTOR_HOLDERS,
  CONTRIBUTOR_OLD_HOLDERS,
  DEFAULT_CONTRIBUTOR,
  DEFAULT_CONTRIBUTOR_COLLECTIVITY,
  DEFAULT_CONTRIBUTOR_OP,
  DEPOSIT_TYPE_COLLECTIVE,
  FRANCE,
  FRMI_TYPE_EXTENSION,
  FRMI_TYPE_HOLDER_CHANGE,
  INSCRIPTION_TYPE_ACT_TITLE,
  INSCRIPTION_TYPE_CORRECTION,
  isFilled,
  PERSONNE_MORALE,
  PERSONNE_PHYSIQUE,
  PROCEDURE_INSCRIPTION,
  Transaction,
  TransactionService
} from '@inpi-marques/components'
import Message from '../constants/Message'

class ContributorService {
    /**
     * Récupère le nom/prénom d'une personne physique ou le
     * nom de la société d'une personne morale
     *
     * @param contributor
     */
    getName = (contributor: Contributor): string => {
      const name = (contributor.type === PERSONNE_PHYSIQUE.value)
        ? `${contributor.lastname || ''} ${contributor.firstname || ''}`
        : (contributor.companyName || contributor.fullCompanyName || '')

      return name.trim()
    }

    /**
     * Récupère le nom/prénom/société d'un mandataire
     *
     * @param contributor
     */
    getAgentName = (contributor: Contributor): string => {
      const name = `${contributor.lastname || ''} ${contributor.firstname || ''} ${contributor.companyName || contributor.fullCompanyName || ''}`
      return name.trim()
    }

    getCompanyInfo = (contributor: Contributor): string => {
      const info = (contributor.type === PERSONNE_MORALE.value)
        ? `${contributor.legalForm ? ` - ${contributor.legalForm}` : ''} ${contributor.siren ? ` - ${contributor.siren}` : ''}`
        : ''

      return info.trim()
    }

    /**
     * Récupère l'adresse de l'intervenant
     */
    getAddress = (address: Address) => {
      const addressInfo = `${address.building ? `${address.building}, ` : ''}
      ${address.label ? address.label : ''}
      ${address.state ? address.state : ''}
      ${address.complement ? `, ${address.complement}` : ''}
      ${address.zipCode ? `, ${address.zipCode} ${address.city}` : ''}`
      return addressInfo.trim()
    }

    /**
     * Récupère le label du code civile de l'intervenant
     * @param contributor
     * @returns
     */
    getCivilityCodeLabelWithSpace = (contributor: Contributor): string => {
      const { firstname, lastname, civilityCode } = contributor

      if (!isFilled(firstname) && !isFilled(lastname)) {
        return ''
      }

      if (civilityCode === CIVILITE_MONSIEUR.value) {
        return `${Message.civilite_monsieur} `
      }
      if (civilityCode === CIVILITE_MADAME.value) {
        return `${Message.civilite_madame} `
      }
      return ''
    }

    filterContributorCivilityCode = (contributor: Contributor): Contributor => {
      if (contributor?.civilityCode && !(contributor.firstname || contributor.lastname)) {
        return { ...contributor, civilityCode: '' }
      }
      return contributor
    }

    /**
     * Récupérer le sous-titre du contributeur
     * @param transaction
     * @param contributorType
     */
    getContributorSubtitle = (transaction: Transaction, contributorType: string): string => {
      if (contributorType.includes(CONTRIBUTOR_DEPOSITORS.value)) {
        if (transaction.subProcedureType === INSCRIPTION_TYPE_ACT_TITLE.value || transaction.subProcedureType === INSCRIPTION_TYPE_CORRECTION.value) {
          let index
          let type = contributorType
          if ((index = contributorType.lastIndexOf(PROCEDURE_INSCRIPTION.value.toLowerCase())) > -1) {
            type = contributorType.substring(0, index)
          }
          return type + transaction.subProcedureType.toLowerCase()
        }
      }
      return contributorType
    }

    /**
     * Savoir si un des intervenants est un destinataire
     * @param transaction
     */
    hasContributorTypeRecipient = (transaction: Transaction): boolean => {
      return !!(transaction.depositors?.some((depositor => depositor.isRecipient)) ||
            transaction.beneficiaries?.some((depositor => depositor.isRecipient)) ||
            transaction.otherApplicants?.some((otherApplicant => otherApplicant.isRecipient)) ||
            transaction.agent?.isRecipient ||
            transaction.signatory?.isRecipient ||
            transaction?.recipient?.isRecipient)
    }

    /**
     * Savoir si l'intervenant est le premier à être ajouté dans la liste
     * @param transaction
     * @param contributorType
     */
    isFirstContributor = (transaction: Transaction, contributorType?: string): boolean => {
      return (transaction.subProcedureType === FRMI_TYPE_EXTENSION.value && transaction.depositors?.length === 0) ||
            (transaction?.subProcedureType === FRMI_TYPE_HOLDER_CHANGE.value && contributorType === CONTRIBUTOR_HOLDERS.value && transaction?.holders?.length === 0)
    }

    /**
     * Savoir si les déposants sont identiques aux titulaires du titre
     * @param holders
     * @param depositors
     */
    areContributorsTheSame = (holders?: Contributor[], depositors?: Contributor[]): boolean => {
      if (depositors?.length !== holders?.length) {
        return false
      } else if (!depositors && !holders) {
        return true
      }
      return !holders?.some((holder: Contributor, index: number) => {
        return (holder.type !== depositors?.[index].type ||
                holder.companyName !== depositors?.[index].companyName ||
                holder.legalForm !== depositors?.[index].legalForm ||
                holder.personFormation !== depositors?.[index].personFormation ||
                holder.companyNameFormation !== depositors?.[index].companyNameFormation ||
                holder.legalFormFormation !== depositors?.[index].legalFormFormation ||
                holder.siren !== depositors?.[index].siren ||
                holder.publicLaw !== depositors?.[index].publicLaw ||
                holder.civilityCode !== depositors?.[index].civilityCode ||
                holder.firstname !== depositors?.[index].firstname ||
                holder.lastname !== depositors?.[index].lastname ||
                holder.phone !== depositors?.[index].phone ||
                holder.email !== depositors?.[index].email ||
                holder?.address?.building !== depositors?.[index]?.address?.building ||
                holder?.address?.city !== depositors?.[index]?.address?.city ||
                holder?.address?.label !== depositors?.[index]?.address?.label ||
                holder?.address?.state !== depositors?.[index]?.address?.state ||
                holder?.address?.country !== depositors?.[index]?.address?.country ||
                holder?.address?.zipCode !== depositors?.[index]?.address?.zipCode ||
                holder?.address?.complement !== depositors?.[index]?.address?.complement)
      })
    }

    /**
     * Vérifie que l'intervenant soit vide ou non
     * @return bool
     */
    isContributorEmpty = (contributor: Contributor): boolean => {
      return !contributor?.idUser &&
            !isFilled(contributor.code) &&
            (!isFilled(contributor.type) || contributor.type === PERSONNE_PHYSIQUE.value) &&
            !contributor.personFormation &&
            !contributor.publicLaw &&
            !isFilled(contributor.email) &&
            !contributor.consentNotificationByEmail &&
            !isFilled(contributor.firstname) &&
            !isFilled(contributor.lastname) &&
            !isFilled(contributor.civilityCode) &&
            (!contributor.address || this.isAddressEmpty(contributor.address)) &&
            !isFilled(contributor.companyName) &&
            !isFilled(contributor.fullCompanyName) &&
            !isFilled(contributor.idCompany) &&
            !isFilled(contributor.siren) &&
            !isFilled(contributor.legalForm) &&
            !isFilled(contributor.phone) &&
            !contributor.manageableQuality &&
            !isFilled(contributor.inscriptionNumber) &&
            !contributor.idFromBeneficiary &&
            !isFilled(contributor.state) &&
            !isFilled(contributor.companyNameFormation) &&
            !isFilled(contributor.legalFormFormation) &&
            !isFilled(contributor.descriptionFormation)
    }

    /**
     * Vérifie que l'intervenant soit vide ou non
     * @return bool
     */
    isAddressEmpty = (address: Address): boolean => {
      return !isFilled(address.label) &&
            (!isFilled(address.country) || address.country === FRANCE) &&
            !isFilled(address.city) &&
            !isFilled(address.state) &&
            !isFilled(address.building) &&
            !isFilled(address.complement) &&
            !isFilled(address.zipCode) &&
            !isFilled(address.postOfficeBox)
    }

    getDefaultContributor = (transaction: Transaction, contributorType?: string): Contributor => {
      if (TransactionService.isFrmiOP(transaction) && contributorType === CONTRIBUTOR_OLD_HOLDERS.value) {
        return DEFAULT_CONTRIBUTOR_OP
      }
      return transaction.subProcedureType === DEPOSIT_TYPE_COLLECTIVE.value && contributorType === CONTRIBUTOR_DEPOSITORS.value ? DEFAULT_CONTRIBUTOR_COLLECTIVITY : DEFAULT_CONTRIBUTOR
    }

    /**
     * Récupération des informations à afficher pour un intervenant
     * @param contributor
     * @returns
     */
    getContributorText = (contributor: Contributor): string => {
      let contributorText: string = contributor.searchableIdentity ?? ''

      // Ajout de la forme juridique
      if (contributor.legalForm) {
        contributorText += ` - ${contributor.legalForm}`
      }

      // Ajout du siren
      if (contributor.siren) {
        contributorText += ` - ${contributor.siren}`
      }

      return contributorText
    }
}
export default new ContributorService()
