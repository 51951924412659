import { Contributor, Country, OverviewContributor } from '@inpi-marques/components'
import React, { FC } from 'react'

interface ContributorListOverviewProps {
    contributors: Contributor[],
    countries: Country[],
    fromFrmiExtension?: boolean,
    procedureType?: string
}

const ContributorListOverview: FC<ContributorListOverviewProps> = ({
  contributors,
  countries,
  fromFrmiExtension,
  procedureType
}) =>
  <ul>
    {contributors.map((contributor: Contributor, index: number) =>
      <li key={index} className='my-2'>
        <OverviewContributor contributor={contributor} countries={countries} fromFrmiExtension={fromFrmiExtension} procedureType={procedureType} isFirst={index === 0} />
      </li>
    )}
  </ul>

export default ContributorListOverview
